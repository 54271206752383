@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap+Condensed:ital,wght@1,600&display=swap');
@import "../utils/Colors.scss";

.form {
	width:100%;
	margin-top: 0rem;
	background: #fff;
	border-radius: 1rem;
	padding: 1rem 1rem 1rem 1rem;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    h4 {
        font-family: 'Asap Condensed', sans-serif;
        text-align: center;
        font-size: x-large;
        color: $headingColor;
    }

}

.form > div {
	margin-bottom: 20px;
}

.form label {
	display: block;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 5px;
	color: #1f1f1f;
}

.form select,
.form textarea{
    font-family: 'Open Sans', sans-serif;
	width: 100%;
	border-radius: 5px;
	border: 2px solid #e2e2e2;
	font-size: 18px;
	padding: 0 0 0 1rem ;
	margin-bottom: 5px;
	color: #343435;
}
.form select:focus{
    outline: none;
	border: 2px solid #0085FF;
}

.form input,
.form textarea {
	font-family: 'Open Sans', sans-serif;
	width: 100%;
	border-radius: 5px;
	border: 2px solid #e2e2e2;
	font-size: 18px;
	padding: 0 -1rem 0 1rem ;
	margin-bottom: 5px;
	color: #1f1f1f;
}

.form input:focus {
	outline: none;
	border: 2px solid #0085FF;
}

.form .error {
	color: #e92b2d;
	font-weight: 600;
	font-size: 12px;
}

.form button[type="submit"] {
	display: block;
	background: #0085FF;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	border: none;
	cursor: pointer;
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	transition: .3s ease all;
}

.form button[type="submit"]:hover {
	background: #0051ff;
}

.form .exito {
	text-align: center;
	font-size: 14px;
	padding-top: 20px;
	color: #02a802;
}

.form input[type="radio"] {
	width: auto;
}
@import "../utils/Colors.scss";
.navbar {
  background: white;
  height: 80px;
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  color: white;
  justify-self: start;
  margin-top: -2rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
  z-index: 1;
}

.nav-links {
  color: $headingColor;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: $lightFontColor;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: $headingColor;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 480px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    position: absolute;
    top: 10rem;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: $lightFontColor;
    left: 0;
    margin-top: 0;
    opacity: 2;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: white;
    font-size: large;
  }

  .nav-links:hover {
    background-color: $headingColor;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: -2rem;
    left: 3rem;

  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -1rem;
    right: 1rem;
    transform: translate(-100%, 60%);
    font-size: 5rem;
    cursor: pointer;
  }

  .fa-times {
    color: $headingColor;
    font-size: 5rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem 0;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}

@media screen and (min-width: 481px) and (max-width: 730px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    position: absolute;
    top: 10rem;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: $lightFontColor;
    left: 0;
    margin-top: 0;
    opacity: 2;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: white;
    font-size: large;
  }

  .nav-links:hover {
    background-color: $headingColor;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: -2rem;
    left: 3rem;

  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -1rem;
    right: 1rem;
    transform: translate(-100%, 60%);
    font-size: 5rem;
    cursor: pointer;
  }

  .fa-times {
    color: $headingColor;
    font-size: 5rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem 0;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}

.dropdown-menu {
    background: #dfdfdf;
    width: 220px;
    position: absolute;
    top: 70px;
    list-style: none;
    text-align: start;
  }
  
  .dropdown-menu li {
    background: #dfdfdf;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background: #dfdfdf;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: rgb(18, 18, 88);
    padding: 16px;
  }
  
  @media screen and (max-width: 480px) {
    .dropdown-menu {
      background: rgb(18, 18, 88);
      width: 100%;
      position: absolute;
      top: 100%;
      list-style: none;
      text-align: start;
      font-size: medium;
    }
    .fa-caret-down {
      display: none;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 730px) {
    .dropdown-menu {
      background: rgb(18, 18, 88);
      width: 100%;
      position: absolute;
      top: 100%;
      list-style: none;
      text-align: start;
      font-size: medium;
    }
    .fa-caret-down {
      display: none;
    }
  }
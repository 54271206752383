.card2 {
  height: 8rem;
  width: 10rem;
  background-color: white;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  display: flex;
  flex-direction: column;
  transition: 0.4s ease-in-out;

  .logo {
    height: 6rem;
    width: 6rem;
    border-radius: 0.6rem;
    position: relative;
    margin-left: 4rem;

    svg {
      color: rgb(31, 31, 180);
      font-size: 2rem;
      justify-content: center;
    }
  }
  .card-title {

    font-size: x-large;
    position: relative;
    text-align: center;
    color: rgb(18, 18, 88);
  }
  .card-text {

    font-size:medium;
    position: relative;
    text-align: center;
    color: rgb(18, 18, 88);
  }
  &:hover {
    box-shadow: #f32a8f2f 5px 5px, #f32a8f1e 10px 10px, #f32a8f02 15px 15px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .card2 {
    height: 6rem;
    width: 65vw;
    padding: 2rem 3.5rem;

    .logo {
      height: 12rem;
      width: 12rem;
      border-radius: 0.6rem;
      position: absolute;
      margin-left: 4rem;
  
      svg {
        color: rgb(31, 31, 180);
        font-size: 4rem;
        justify-content: center;
      }
    }

    .card-title {
     
      margin-left: 10rem;
      font-size: 3rem;
      color: rgb(88, 62, 18);
    }
    .card-text {
      margin-left: 10rem;
      margin-top: -2.5rem;
      font-size: 2rem;
      color: rgb(18, 18, 88);
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .card2 {
    height: 6rem;
    width: 25vw;
    padding: 3rem 3.5rem 4rem 3.5rem;
    .logo {
      height: 4rem;
      width: 4rem;
      position: relative;
      margin-left: 10vw;
      svg {
        font-size: 3rem;
        text-align: center;
      }
    }
    .card-title {
      margin-bottom: 1rem;
      font-size: 3rem;
      color: rgb(18, 18, 88);
    }
    .card-text {
      margin-left: -2rem;
      margin-top: -3rem;
      font-size: 1.8rem;
      color: rgb(18, 18, 88);
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .card2 {
    height: 8rem;
    width: 13rem;
  }
}

@import "../utils/Colors.scss";
.starter2-container {
  background-color: white;
  .container {
    margin: 0 10%;
    padding: 5rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    .details {
      p {
        color: $lightFontColor;
        line-height: 1.3rem;
        font-size: large;
        text-align: justify;
        width: 90%;
      }
      button {
        margin-top: 3rem;
      }
    }
    .cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
     
      
    }
    .image{
        width: 120%;
    }
   
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .starter2-container {
    .container {
      grid-template-columns: 1fr;
      .details {
        p {
          font-size: 2.3rem;
          line-height: 2rem;
          width: 100%;
        }
      }
      .cards {
        grid-template-columns: 1fr;
      }
      .image{
        width: 100%;
    }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .starter2-container {
    .container {
      grid-template-columns: 1fr;
      .details {
        p {
          font-size: 2.5rem;
          line-height: 2.5rem;
          width: 100%;
        }
      }
      .cards {
        grid-template-columns: 1fr;
      }
      .image{
        width: 80%;
        text-align: center;
        position: relative;
        margin-left: 10%;
    }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .starter2-container {
    .container {
      gap: 1rem;
      .cards {
        gap: 1rem;
      }
      .image{
        width: 140%;
    }
    }
  }
}

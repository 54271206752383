@import "../utils/Colors.scss";

.brand {
  cursor: pointer;
  span {
    text-transform: uppercase;
    font-size: xx-large;
   
    padding: 0.6rem 1rem;
    border-radius: 0.3rem;
    color: $blueColor;
    font-weight: bolder;
    letter-spacing: 0.2rem;
    
  }
  .imagenGrupoConsultor{
    width: 120px;
    margin-top: 50px;
  }
}
.footer {
  span {
    color: white;
    border-color: white;
    
  }
}
